<template>
  <v-container>
    <div class="d-flex mb-3">
      <h1 class="headline">
        {{ $t('medicalTests.request_analytical_test') }}
      </h1>
    </div>
    <div>
      <v-layout wrap>
        <v-flex md6>
          <div class="mr-md-1 d-flex flex-column">
            <v-card elevation="2" class="medical-tests-top-container">
              <v-card-text>
                <PatientInfo v-model="selectedPatient" :patient-id="patientId" />
              </v-card-text>
            </v-card>
            <v-card elevation="2" class="mt-6">
              <v-card-text>
                <h1 class="subtitle-1 font-weight-medium mb-3">
                  {{ $t('medicalTests.select_medical_test') }}
                </h1>
                <AnalyticalTestsSelector v-model="selectedOtherMedicalTests" />
              </v-card-text>
            </v-card>
          </div>
        </v-flex>
        <v-flex md6>
          <div class="ml-md-1 d-flex flex-column fill-height">
            <v-card elevation="2" class="medical-tests-top-container">
              <v-card-text>
                <h1 class="subtitle-1 font-weight-medium mb-3">
                  {{ $t('medicalTests.select_medical_test_type') }}
                </h1>
                <MedicalOrderTemplatesSelector
                  v-model="selectedMedicalTests"
                  :type="analyticalType"
                  :label="`${$t('medicalTests.analytical_tests_list_types')}`"
                />
              </v-card-text>
            </v-card>
            <v-card elevation="2" class="mt-6 flex-grow-1" height="472px">
              <v-card-text class="d-flex flex-column fill-height overflow-y-auto">
                <div v-if="allSelectedMedicalTests.length">
                  <div v-if="selectedMedicalTests.length">
                    <h1 class="subtitle-1 font-weight-medium mb-3">
                      {{ $t('medicalTests.medical_tests_requested') }}
                    </h1>
                    <div class="mt-3">
                      <div
                        v-for="(medicalTest, index) in selectedMedicalTests"
                        :key="index"
                        class="d-flex flex-row align-center justify-space-between"
                      >
                        <span>
                          {{ medicalTest.code ? `${medicalTest.code} - ` : '' }}{{ medicalTest.description }}
                        </span>
                        <div class="text-center">
                          <v-btn icon color="error" :disabled="submitting" @click="removeSelectedAct(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedOtherMedicalTests.length">
                    <h1 class="subtitle-1 font-weight-medium mb-3">
                      {{ $t('medicalTests.other_medical_tests_requested') }}
                    </h1>
                    <div class="mt-3">
                      <div
                        v-for="(otherMedicalTest, index) in selectedOtherMedicalTests"
                        :key="index"
                        class="d-flex flex-row align-center justify-space-between"
                      >
                        <span>
                          {{ otherMedicalTest.code ? `${otherMedicalTest.code} - ` : ''
                          }}{{ otherMedicalTest.description }}
                        </span>
                        <div class="text-center">
                          <v-btn icon color="error" :disabled="submitting" @click="removeOtherSelectedAct(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="d-flex flex-row align-center justify-center fill-height">
                  <div class="d-flex flex-row align-center">
                    <v-icon large>mdi-plus-circle</v-icon>
                    <span class="ml-3 text-h6">{{ $t('medicalTests.select_some_test') }}</span>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-flex>
      </v-layout>
    </div>
    <v-layout wrap class="mt-6">
      <v-flex xs12 sm12 md6>
        <v-card elevation="2" class="mr-md-1">
          <v-card-text>
            <v-textarea
              v-model="description"
              dense
              outlined
              rows="3"
              :label="`${$t('medicalTests.medical_test_description')} *`"
              :error-messages="descriptionValidationError"
            />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md6>
        <v-card elevation="2" class="ml-md-1">
          <v-card-text>
            <v-textarea dense outlined rows="3" counter :label="`${$t('medicalTests.medical_test_recommendations')}`" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="text-right mt-3">
      <v-btn class="ma-2" color="primary" outlined @click="cancel">
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn color="primary" :disabled="submitting" :loading="submitting" @click="save">
        {{ $t('medicalTests.medical_test_request') }}
      </v-btn>
    </div>
    <v-dialog v-model="showPDFModal" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>PDF generado</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showPDFModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-center">
          <PDFViewer :pdf-url="pdfUrl" :generated-pdf="generatedPdf" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { baseNameRoutes } from '@/router/paths';
import AnalyticalTestsSelector from '@/components/MedicalTests/AnalyticalTestsSelector';
import MedicalOrderTemplatesSelector from '@/components/MedicalTests/MedicalOrderTemplatesSelector';
import PDFViewer from '@/components/PDFViewer';
import PatientInfo from '@/components/MedicalTests/PatientInfo';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, minLength, required } from '@vuelidate/validators';
import { filesUrl, getHeader, medicalTestsUrl, professionalHasSign } from '@/config/config';
import DownloadFile from '@/mixins/DownloadFile';
import axios from 'axios';

const TEXT_MAX_LENGTH = 256;
const MEDICAL_ORDERS_ENDPOINT = medicalTestsUrl + 'medical-orders';
const ANALYTICAL_TYPE = 'analytical';

export default {
  components: { PatientInfo, MedicalOrderTemplatesSelector, AnalyticalTestsSelector, PDFViewer },

  mixins: [DownloadFile],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data: function() {
    return {
      description: '',
      recommendation: '',
      selectedMedicalTests: [],
      selectedOtherMedicalTests: [],
      selectedPatient: this.patientId,
      pdfUrl: null,
      generatedPdf: null,
      showPDFModal: false,

      submitting: false,
      submittedWithErrors: false,
    };
  },

  validations() {
    return {
      description: {
        required: helpers.withMessage(this.$t('common.obligatorio'), required),
        maxLength: helpers.withMessage(
          this.$t('errors.input_tam_maximo_superado', {
            input: this.$t('medicalTests.medical_test_description'),
            tam: TEXT_MAX_LENGTH,
          }),
          maxLength(TEXT_MAX_LENGTH)
        ),
        $autoDirty: true,
      },
      recommendation: {
        maxLength: helpers.withMessage(
          this.$t('errors.input_tam_maximo_superado', {
            input: this.$t('medicalTests.medical_test_recommendations'),
            tam: TEXT_MAX_LENGTH,
          }),
          maxLength(TEXT_MAX_LENGTH)
        ),
        $autoDirty: true,
      },
      allSelectedMedicalTests: {
        minLength: helpers.withMessage(this.$t('common.obligatorio'), minLength(1)),
      },
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
    }),
    allSelectedMedicalTests() {
      return [...this.selectedMedicalTests, ...this.selectedOtherMedicalTests];
    },
    descriptionValidationError() {
      return this.submittedWithErrors ? this.v$.description.$errors.map(error => error.$message) : null;
    },
    analyticalType() {
      return ANALYTICAL_TYPE;
    },
  },

  watch: {
    showPDFModal(val) {
      if (!val) {
        this.redirectToDashboard();
      }
    },
  },

  async mounted() {
    if (!this.permissions.moduleMedicalTests) {
      this.redirectToDashboard();
    }
    const hasSign = await this.professionalHasSign();
    if (!hasSign) {
      await this.$router.push({ name: baseNameRoutes.signed });
    }
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    async professionalHasSign() {
      const response = await this.$http.get(professionalHasSign, { headers: getHeader() });
      return response.status === 200;
    },

    redirectToDashboard() {
      this.$router.push({ name: baseNameRoutes.dashboard });
    },

    cancel() {
      this.redirectToDashboard();
    },

    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },

    showAlertMsg(color, icon, msg) {
      this.showAlert({
        color: color,
        icon: icon,
        message: msg,
      });
    },

    save() {
      this.v$.$touch();
      this.submitting = true;

      if (this.v$.$invalid) {
        this.submittedWithErrors = true;
        this.submitting = false;
        return;
      }

      this.create();
    },

    async fetchMedicalOrderFile(id) {
      const raw = await fetch(`${MEDICAL_ORDERS_ENDPOINT}/${id}`, {
        headers: getHeader(),
      });
      return raw.json();
    },

    async downloadPdf(url) {
      const res = await axios.get(url, {
        headers: getHeader(),
        responseType: 'blob',
      });

      return window.URL.createObjectURL(new Blob([res.data]));
    },

    async create() {
      this.submitting = true;

      try {
        const payload = {
          patientId: this.selectedPatient,
          description: this.description,
          acts: this.allSelectedMedicalTests.map(act => act.id),
          type: ANALYTICAL_TYPE,
        };

        if (this.recommendation) {
          payload.recommendation = this.recommendation;
        }

        const response = await this.$http.post(MEDICAL_ORDERS_ENDPOINT, payload, { headers: getHeader() });

        if (response.status === 200) {
          this.generatedPdf = await this.downloadPdf(`${filesUrl}/${response.body.id}/download`);
          this.showAlertMsg('success', 'mdi-check', this.$t('medicalTests.request_medical_test_success'));
          this.submitting = false;
          this.showPDFModal = true;
        } else {
          this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
          this.submitting = false;
        }
      } catch (e) {
        this.showAlertMsg('error', 'mdi-alert-circle', this.$t('errors.try_again'));
      } finally {
        this.submitting = false;
      }
    },

    removeSelectedAct(index) {
      this.selectedMedicalTests.splice(index, 1);
    },

    removeOtherSelectedAct(index) {
      this.selectedOtherMedicalTests.splice(index, 1);
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  .medical-tests-top-container {
    height: 115px;
  }
}
</style>
