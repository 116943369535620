<template>
  <modal
    :title="
      isValidCollegiateNumber && collegiateNumberAllowEmmit ? $t('medicalTests.select_patient') : $t('common.notice')
    "
    :show="show"
  >
    <PatientsSelector @patientSelected="onSubmit" />
    <div class="actions">
      <v-btn color="primary" dark text class="mr-3" @click="close">
        {{ $t('common.cancel') }}
      </v-btn>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import PatientsSelector from "@/components/patients/PatientsSelector/PatientsSelector.vue";

export default {
  components: {
    PatientsSelector,
  },
  model: {
    prop: 'show',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      error: null,
      searchData: [],
      loading: false,
      butColor: 'info',
      isValidCollegiateNumber: false,
      collegiateNumberAllowEmmit: false,
      trialMode: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      appThemePrimary: state => state.app.styles.themePrimary,
    })
  },

  created() {
    this.setButtonColor();
  },

  methods: {
    onSubmit(patient) {
      if (patient !== null) {
        this.$emit('patientSelected', patient);
      }
    },
    close() {
      this.$emit('input', false);
    },

    setButtonColor() {
      if (this.appThemePrimary) {
        this.butColor = this.appThemePrimary;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.error-msj {
  color: #dc3545;
}

.fixheight {
  flex: unset !important;
}

::v-deep .v-application--wrap {
  min-height: unset !important;
}
</style>
