<template>
  <v-select
    v-model="selectedMedicalOrderTemplate"
    :label="label"
    outlined
    dense
    hide-details
    :items="medicalOrderTemplates"
    item-text="description"
    item-value="id"
    :disabled="isLoading"
  />
</template>

<script>
import { getHeader, medicalTestsUrl } from '@/config/config';
import { sortAlphabetically } from '@/utils/sort';

const MEDICAL_TESTS_ENDPOINT = `${medicalTestsUrl}medical-order-templates`;

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: function() {
    return {
      medicalOrderTemplates: [],
      isLoadingMedicalOrderTemplates: false,
      isLoading: false,
      selectedMedicalOrderTemplate: null,
      page: 1,
      itemsPerPage: 100,
    };
  },

  watch: {
    selectedMedicalOrderTemplate(val) {
      if (val) {
        this.$emit(
          'input',
          Array.from(this.medicalOrderTemplates.find(medicalOrderTemplate => medicalOrderTemplate.id === val).acts)
        );
      }
    },
  },
  created() {
    this.loadMedicalOrderTemplates();
  },

  methods: {
    async loadMedicalOrderTemplates() {
      const response = await this.fetchMedicalOrderTemplates();
      sortAlphabetically(response.templates, 'description');
      this.medicalOrderTemplates = response.templates;
    },
    async fetchMedicalOrderTemplates() {
      const url = new URL(MEDICAL_TESTS_ENDPOINT);
      const params = {
        page: this.page,
        size: this.itemsPerPage,
      };

      if (this.type) {
        params.type = this.type;
      }

      url.search = new URLSearchParams(params).toString();

      const raw = await fetch(url, {
        headers: getHeader(),
      });
      return raw.json();
    },
  },
};
</script>
