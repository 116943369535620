<template>
  <div>
    <div class="d-flex flex-row align-top justify-space-between">
      <h1 class="subtitle-1 font-weight-medium mb-3">
        {{ $t('medicalTests.patient_info') }}
      </h1>
      <v-btn color="primary" outlined small class="btn-change-patient" @click="openSearchPatient">
        {{ $t('recipe.change_patient') }}
      </v-btn>
    </div>

    <div v-if="loading" class="d-flex flex-column justify-center align-center">
      {{ $t('common.cargando') }}
    </div>
    <div v-else class="d-flex flex-row flex-wrap align-center">
      <div class="flex-grow-1 flex-shrink-0">
        <span class="text-md-body-2 same-line"
          >{{ patient.name }} <icon :type="genderIcon" :width="20" class="gender-icon" /> {{ patientAge }}
          {{ $t('recipe.years') }} ({{ $moment(patient.birthdate).format('DD/MM/YYYY') }})</span
        >
        <div class="d-flex flex-row align-center text-md-body-2">
          <v-icon size="18" class="mr-1">mdi-email</v-icon>
          <span>{{ patient.email }}</span>
        </div>
      </div>
      <div>
        <div class="d-flex flex-row align-center text-md-body-2">
          <v-icon size="18" class="mr-1">mdi-phone</v-icon>
          <span>{{ patient.phone }}</span>
        </div>
        <div class="d-flex flex-row align-center text-md-body-2">
          <v-icon size="18" class="mr-1">mdi-card-account-details-outline</v-icon>
          <span>{{ patient.dni }}</span>
        </div>
      </div>

      <SearchPatientModal
        v-model="isSearchPatientModule"
        :professional-id="professionalId"
        @patientSelected="patientSelected($event)"
      />
    </div>
  </div>
</template>

<script>
import { usePatient } from '@/use/Patients/usePatient';
import SearchPatientModal from '@/components/MedicalTests/SearchPatientModal';
import { watch } from 'vue';

export default {
  components: { SearchPatientModal },
  props: ['patientId'],

  setup: function(props, { emit }) {
    const {
      patient,
      loading,
      genderIcon,
      patientAge,
      isSearchPatientModule,
      fetchPatient,
      changePatient,
      toggleLoading,
      patientSelected,
      openSearchPatient,
    } = usePatient();

    watch(
      () => patient.value,
      currentValue => {
        emit('input', currentValue.id);
      }
    );

    if (props.patientId) {
      fetchPatient(props.patientId);
    } else {
      toggleLoading();
    }

    return {
      patient,
      loading,
      genderIcon,
      patientAge,
      isSearchPatientModule,
      fetchPatient,
      changePatient,
      toggleLoading,
      patientSelected,
      openSearchPatient,
    };
  },

  data: function() {
    return {
      professionalId: JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id,
    };
  },
};
</script>

<style scoped>
.btn-change-patient {
  height: 26px;
}
</style>
