import { getHeader, patientsSearchUrl } from '@/config/config';
import axios from 'axios';

export const getPatients = async (professionalId, {page, size}, search) => {
  const params = {
    searchString: search ?? '_all_',
    page: page ?? 1,
    size: size ?? 20,
    profesionalId: professionalId,
  };

  const raw = await axios.post(patientsSearchUrl, params, {
    headers: getHeader(),
  });
  return raw.data;
};
