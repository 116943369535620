import { ref, computed } from 'vue';
import { getPatients } from '@/services/patients';

const DEFAULT_ITEMS_PER_PAGE = 20;

export function usePatientsSelector() {
  const patients = ref([]);
  const currentPatient = ref(null);

  const query = ref(null);
  const page = ref(1);
  const lastPage = ref(1);

  const isLoadingPatients = ref(false);
  const isLoadingCurrentPatient = ref(false);
  const isNetworkError = ref(false);

  const retrievePatients = async (professionalId) => {
    try {
      isNetworkError.value = false;
      isLoadingPatients.value = true;
      currentPatient.value = null;
      page.value = 1;
      lastPage.value = 1;

      let response;

      if (query.value) {
        response = await getPatients(professionalId, {
          page: page.value,
          size: DEFAULT_ITEMS_PER_PAGE,
        }, query.value);
      } else {
        response = await getPatients(professionalId, {
          page: page.value,
          size: DEFAULT_ITEMS_PER_PAGE,
        });
      }
      patients.value = response.data;
      lastPage.value = response.meta.lastPage;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingPatients.value = false;
    }
  };

  const morePatients = async (professionalId) => {
    try {
      page.value = page.value + 1;
      isNetworkError.value = false;
      isLoadingPatients.value = true;
      let response;

      if (query.value) {
        response = await getPatients(
          professionalId,
          {
            page: page.value,
            size: DEFAULT_ITEMS_PER_PAGE,
          },
          query.value
        );
      } else {
        response = await getPatients(
          professionalId,
          {
            page: page.value,
            size: DEFAULT_ITEMS_PER_PAGE,
          },
        );
      }

      patients.value = [
        ...patients.value,
        ...response.items
      ];
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingPatients.value = false;
    }
  };

  const reset = () => {
    page.value = 1;
    lastPage.value = 1;
    query.value = null;
  }

  const clearQuery = () => {
    query.value = null;
  }

  const isLastPage = computed(() => {
    return lastPage.value === page.value;
  });

  const patientsFullnameWithDocument = computed(() => {
    return patients.value.map((patient) => {
      patient.text = `${patient.fullname} (${patient.document})`;
      return patient;
    });
  });

  return {
    query,
    patients,
    currentPatient,
    isLoadingPatients,
    isLoadingCurrentPatient,
    isNetworkError,
    patientsFullnameWithDocument,
    isLastPage,
    clearQuery,
    reset,
    retrievePatients,
    morePatients,
  };
}
