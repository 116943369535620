<template>
  <div class="fill-height overflow-y-auto px-1">
    <div v-if="!isLoadingSubGroups" class="d-flex flex-row flex-wrap analytical-test-list">
      <div v-for="(featuredSubGroup, index) in activeSubGroups" :key="index" class="analytical-test">
        <v-switch
          v-model="selectedGroup"
          inset
          hide-details
          :label="featuredSubGroup.description"
          :value="featuredSubGroup.id"
          :input-value="featuredSubGroup.id"
        />
      </div>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center analytical-test-list">
      <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
    </div>
    <v-dialog v-model="showActsDialog" max-width="750px" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t('medicalTests.select_acts') }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showActsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div
            v-if="isLoadingActs"
            class="d-flex flex-column align-center justify-center analytical-acts-modal__loader"
          >
            <v-progress-circular indeterminate color="primary" :size="70" :width="3" />
          </div>
          <div v-else class="d-flex flex-wrap analytical-acts-list">
            <v-checkbox
              v-for="(act, index) in activeActs"
              :key="index"
              v-model="selectedActs"
              class="analytical-act"
              hide-details
              :value="act"
              :value-comparator="(a, b) => a.id === b.id"
              :label="`${act.code ? act.code + ' - ' : ''}${act.description}`"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FEATURED_MODES, useNomenclator } from '@/use/MedicalTests/useNomenclator';
import { watch } from 'vue';

export default {
  setup: function(props, { emit }) {
    const {
      loadAllSubGroups,
      loadActs,
      setFeatureMode,
      setItemsPerPage,
      setSubGroup,
      isLoadingSubGroups,
      isLoadingActs,
      selectedActs,
      activeActs,
      activeSubGroups,
    } = useNomenclator(JSON.parse(window.localStorage.getItem('auth_ehealth')).user.professional_id, true);

    setFeatureMode(FEATURED_MODES.featured);
    setItemsPerPage(10000);
    loadAllSubGroups();

    watch(
      () => selectedActs.value,
      currentValue => {
        return emit('input', currentValue);
      }
    );

    return {
      loadAllSubGroups,
      loadActs,
      setItemsPerPage,
      setFeatureMode,
      setSubGroup,
      isLoadingSubGroups,
      isLoadingActs,
      selectedActs,
      activeActs,
      activeSubGroups,
    };
  },
  data: function() {
    return {
      selectedGroup: null,
      showActsDialog: false,
    };
  },
  computed: {
    medicaltTestsClass() {
      return {
        'd-flex': true,
        'flex-column': true,
        'flex-wrap': true,
      };
    },
  },
  watch: {
    selectedGroup(val) {
      if (val !== null) {
        this.setSubGroup(val);
        this.showActsDialog = true;
      }
    },
    showActsDialog() {
      if (!this.showActsDialog) {
        this.selectedGroup = null;
      }
    },
  },
  methods: {
    closeActsDialog() {
      this.showActsDialog = false;
    },
  },
};
</script>

<style>
.analytical-test-list {
  height: 400px;
}

.analytical-test {
  flex: 1 1 285px !important;
}

.analytical-acts-list {
  max-height: 500px;
}
.analytical-acts-modal__loader {
  min-height: 200px;
}
.analytical-act {
  flex: 1 1 265px !important;
}
</style>
