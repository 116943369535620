<template>
  <div class="patients-selector">
    <p class="patients-selector__label">{{ $t('patients_selector.select_patient') }}</p>
    <v-autocomplete
      v-model="currentPatient"
      :items="patientsFullnameWithDocument"
      :loading="isLoadingPatients"
      outlined
      hide-selected
      clearable
      :placeholder="$t('patient.search')"
      item-text="text"
      item-value="id"
      :error-messages="validationError"
      :disabled="disabled"
      @update:search-input="onChangeSearchVal($event)"
      @click:clear="retry"
    >
      <template slot="item" slot-scope="{ item }">
        <div class="py-1">
          <span class="patients-selector__item">
            {{ item.text }}
          </span>
        </div>
      </template>
      <template slot="no-data">
        <div class="patients-selector__no-data primary--text text-body-1 px-4 py-2">
          <PatientsSelectorNetworkError v-if="isNetworkError" :retry="retry" :loading="isLoadingPatients" />
          <div v-else>
            <v-progress-circular v-if="isLoadingPatients" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
              isLoadingPatients ? $t('patients_selector.loading_patients') : $t('patients_selector.not_found_patients')
            }}</span>
          </div>
        </div>
      </template>
      <template v-slot:append-item>
        <div v-intersect="endIntersect" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { usePatientsSelector } from '@/use/Patients/usePatientsSelector';
import PatientsSelectorNetworkError from '@/components/patients/PatientsSelector/PatientsSelectorNetworkError.vue';
import debounce from "@/utils/debounce";

export default {
  name: 'PatientsSelector',
  components: {
    PatientsSelectorNetworkError,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    validationError: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  setup() {
    const {
      query,
      patients,
      patientsFullnameWithDocument,
      currentPatient,
      isLoadingPatients,
      isLoadingCurrentPatient,
      isNetworkError,
      isLastPage,
      clearQuery,
      reset,
      retrievePatients,
      morePatients,
    } = usePatientsSelector();

    return {
      query,
      patients,
      patientsFullnameWithDocument,
      currentPatient,
      isLoadingPatients,
      isLoadingCurrentPatient,
      isNetworkError,
      isLastPage,
      clearQuery,
      reset,
      retrievePatients,
      morePatients,
    };
  },

  data: function() {
    return {
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    };
  },

  watch: {
    currentPatient(val) {
      if (val) {
        this.$emit('patientSelected', val);
      }
    },
  },

  created() {
    console.log("CREATING")
    this.retrievePatients(this.authUser.user.professional_id);
  },

  methods: {
    onChangeSearchVal(searchString) {
      if (!searchString) {
        return;
      }

      debounce(() => {
        this.query = searchString;
        this.retrievePatients(this.authUser.user.professional_id);
      }, 500);
    },
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && !this.isLastPage) {
        this.morePatients(this.authUser.user.professional_id);
      }
    },
    retry() {
      this.reset();
      this.retrievePatients(this.authUser.user.professional_id);
    },
  }
};
</script>

<style lang="scss">
$black: #212121;
$grey: #f1f1f1;
$highlightColor: #daf2f8;

.patients-selector {
  &__no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    font-size: 16px;
    color: $black;
    margin-bottom: 0.25rem !important;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__item {
    font-size: rem(16);
    color: $black;
    font-weight: 500;
  }
}

.patients-selector {
  & .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border-color: $grey;
    border-width: 0.125rem;
  }
}
</style>
