<template>
  <div class="pdf-viewer">
    <div v-show="loading">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <div v-show="!loading">
      <div class="d-flex flex-row justify-end pdf-viewer--actions">
        <v-btn v-if="printable" icon @click="print()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn icon @click="download">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </div>
      <div class="pdf-viewer--content" />
    </div>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import print from 'print-js';
import { v4 as uuidv4 } from 'uuid';
import {getHeader} from "@/config/config";

export default {
  props: ['printable', 'generatedPdf', 'pdfUrl'],

  async created() {
    await this.showDialogPDF(this.generatedPdf);
  },

  data: function() {
    return {
      loading: true,
    };
  },

  methods: {
    async showDialogPDF(url) {
      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
      const loadingTask = pdfjsLib.getDocument({
        url: url,
        httpHeaders: getHeader()
      });

      const outputScale = window.devicePixelRatio || 1;

      loadingTask.promise.then(pdf => {
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function(page) {
            const desiredWidth = document.querySelector('.pdf-viewer .pdf-viewer--content').offsetWidth;

            const viewport = page.getViewport({ scale: 1 });

            const scale = desiredWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            document.querySelector('.pdf-viewer .pdf-viewer--content').appendChild(canvas);
            document.querySelector('.pdf-viewer .pdf-viewer--content').appendChild(document.createElement('br'));

            canvas.height = scaledViewport.height * outputScale;
            canvas.width = scaledViewport.width * outputScale;
            canvas.style.width = Math.floor(scaledViewport.width) + 'px';
            canvas.style.height = Math.floor(scaledViewport.height) + 'px';

            const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            const renderContext = {
              canvasContext: context,
              transform: transform,
              viewport: scaledViewport,
            };
            page.render(renderContext);
          });
        }

        this.loading = false;
      });
    },
    print() {
      print(this.pdfUrl);
    },
    download() {
      const link = document.createElement('a');
      link.href = this.generatedPdf;
      link.setAttribute('download', `${uuidv4()}.pdf`);
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style>
.pdf-viewer {
  position: relative;
}

.pdf-viewer--actions {
  position: absolute;
  right: 26px;
}
</style>
